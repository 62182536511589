import React, { useState, useEffect, FormEvent } from 'react';
import api from '../../server/api';
import logoImg from '../../assets/logo.png';
import { Title, Form, Users, Error, FormPhone } from './styles';

interface ICnpj {
  id: number;
  cnpj: number;
  empresa: string;
  nomefantasia: string;
  distribuidor: string;
  vendedor: string;
  segmento: string;
  porte: string;
  decisorcompra: string;
  regiao: string;
  uf: string;
  municipio: string;
  bairro: string;
  ptoreferencia: string;
  site: string;
  tel01: string;
  tel02: string;
  tel03: string;
  tel04: string;
}

const Search: React.FC = () => {
  const [searchCnpj, setSearchCnpj] = useState('');
  const [inputError, setInputError] = useState('');
  const [updatePhone01, setUpdatePhone01] = useState('');
  const [updatePhone02, setUpdatePhone02] = useState('');
  const [updatePhone03, setUpdatePhone03] = useState('');
  const [updatePhone04, setUpdatePhone04] = useState('');
  const [users, setUsers] = useState<ICnpj[]>([]);

  async function handleSearchCnpj(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();

    if (!searchCnpj) {
      setInputError('Digite um CNPJ no campo de pesquisa');
      return;
    }

    const { data } = await api.get<ICnpj[]>(`buscar/${searchCnpj}`);
    setUsers(data);
    setInputError('');

    if (data.length === 0) {
      setInputError('CNPJ não encontrado');
    }
  }

  useEffect(() => {
    if (users[0]?.tel01 !== undefined) {
      setUpdatePhone01(users[0]?.tel01);
    }
    if (users[0]?.tel02 !== undefined) {
      setUpdatePhone02(users[0]?.tel02);
    }
    if (users[0]?.tel03 !== undefined) {
      setUpdatePhone03(users[0]?.tel03);
    }
    if (users[0]?.tel04 !== undefined) {
      setUpdatePhone04(users[0]?.tel04);
    }
  }, [users[0]?.tel01, users[0]?.tel02, users[0]?.tel03, users[0]?.tel04]);

  async function handleAddPhone(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();
    const { data } = await api.put('atualizartelefone', {
      id: users[0].id,
      tel01: updatePhone01,
      tel02: updatePhone02,
      tel03: updatePhone03,
      tel04: updatePhone04,
    });

    if (data !== 0) {
      alert('Atualizado com sucesso.');
    }

    setSearchCnpj('');
    setUsers([]);
  }

  return (
    <>
      <img src={logoImg} alt="logo" />

      <Title>Buscar Distribuidora:</Title>

      <Form hasError={!!inputError} onSubmit={handleSearchCnpj}>
        <input
          value={searchCnpj}
          onChange={(e) => setSearchCnpj(e.target.value)}
          placeholder="Digite o CNPJ para pesquisar"
          type="text"
          name="cnpj"
          id="cnpj"
          maxLength={15}
        />
        <button type="submit">Pesquisar</button>
      </Form>

      {inputError && <Error>{inputError}</Error>}

      <Users>
        {users.map((user) => (
          <div key={user.id}>
            <h2>
              <strong>CNPJ: </strong>
              {user.cnpj}
            </h2>
            <ul>
            <li>
              <strong>Empresa: </strong>
              {user.empresa}
            </li>
            <li>
              <strong>Nome Fantasia: </strong>
              {user.nomefantasia}
            </li>
            <li>
              <strong>Distribuidora: </strong>
              {user.distribuidor}
            </li>
            <li>
              <strong>Vendedor: </strong>
              {user.vendedor}
            </li>
            <li>
              <strong>Segmento: </strong>
              {user.segmento}
            </li>
            <li>
              <strong>Porte: </strong>
              {user.porte}
            </li>
            <li>
              <strong>Decisor de compra: </strong>
              {user.decisorcompra}
            </li>
            <li>
              <strong>Região: </strong>
              {user.regiao}
            </li>
            <li>
              <strong>UF: </strong>
              {user.uf}
            </li>
            <li>
              <strong>Município: </strong>
              {user.municipio}
            </li>
            <li>
              <strong>Bairro: </strong>
              {user.bairro}
            </li>
            <li>
              <strong>Ponto de Referência: </strong>
              {user.ptoreferencia}
            </li>
            <li>
              <strong>Site: </strong>
              {user.site}
            </li>
            </ul>


            <h3>Cadastrar novo telefone</h3>
            <strong>
              Digite o novo número de telefone sem colocar parenteses ou traços
            </strong>

            <FormPhone onSubmit={handleAddPhone}>
              <input
                value={updatePhone01}
                onChange={(e) => setUpdatePhone01(e.target.value)}
                placeholder="Digite o telefone"
                type="text"
                name="tel01"
                id="tel01"
                maxLength={15}
              />
              <input
                value={updatePhone02}
                onChange={(e) => setUpdatePhone02(e.target.value)}
                placeholder="Digite o telefone"
                type="text"
                name="tel02"
                id="tel02"
                maxLength={15}
              />
              <input
                value={updatePhone03}
                onChange={(e) => setUpdatePhone03(e.target.value)}
                placeholder="Digite o telefone"
                type="text"
                name="tel03"
                id="tel03"
                maxLength={15}
              />
              <input
                value={updatePhone04}
                onChange={(e) => setUpdatePhone04(e.target.value)}
                placeholder="Digite o telefone"
                type="text"
                name="tel04"
                id="tel04"
                maxLength={15}
              />

              <button type="submit">Cadastrar telefone</button>
            </FormPhone>
          </div>
        ))}
      </Users>
    </>
  );
};

export default Search;
