import styled, { css } from 'styled-components';

interface FormProps {
  hasError: boolean;
}

export const Title = styled.h1`
  margin-top: 50px;
  font-size: 36px;
  color: #fff;
`;

export const Form = styled.form<FormProps>`
  display: flex;
  margin-top: 15px;

  input {
    flex: 1px;
    height: 70px;
    padding: 0 24px;
    border: 0;
    border-radius: 5px 0 0 5px;
    border: 2px solid #fff;
    border-right: 0;

    font-size: 24px;
    color: #3a3a3a;
    line-height: 56px;

    ${(props) =>
    props.hasError &&
    css`
        border-color: #c53030;
      `}

    &::placeholder {
      color: #a8a8b3;
    }
  }

  button {
    width: 210px;
    height: 70px;
    background-color: #ffca05;
    border: 0;
    border-radius: 0 5px 5px 0;

    font-size: 18px;
    color: #182a74;
    font-weight: bold;
    text-transform: uppercase;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const Error = styled.span`
  display: block;
  margin-top: 8px;
  color: #c53030;
`;

export const Users = styled.div`
  margin-top: 50px;

  div {
    padding: 24px;
    background-color: #fff;
    border-radius: 5px;

    h2 {
      font-size: 24px;
      font-weight: bold;
      color: #00205c;
    }

    h3 {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 2px solid #cbcbd6;
      font-size: 30px;
      font-weight: bold;
      color: #182a74;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(2,1fr);
      gap: 5px 15px;
      margin-top: 20px;
      list-style: none;
    }

    li {
      margin-top: 4px;
      font-size: 16px;
      color: #a8a8b3;
    }

    strong {
      font-size: 16px;
      color: #3d3d4d;
    }
  }

  svg {
    margin-left: auto;
    color: #cbcbd6;
  }
`;

export const FormPhone = styled.form`
  margin-top: 25px;
  text-align: center;

  div {
    padding: 0;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    color: #3d3d4d;
  }

  input {
    width: 461px;
    height: 50px;
    margin-top: 5px;
    padding: 0 16px;
    border-radius: 5px;
    border: 1px solid #a8a8b3;

    font-size: 16px;
    color: #3a3a3a;

    &::placeholder {
      color: #a8a8b3;
    }

    &:nth-child(odd) {
      margin-right: 10px;
    }
  }

  button {
    display: block;
    width: 100%;
    height: 50px;
    margin-top: 10px;
    background-color: #ffca05;
    border: 0;
    border-radius: 5px;

    font-size: 16px;
    color: #182a74;
    font-weight: bold;
    text-transform: uppercase;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;
